@import "../../styles/breakpoints";

.title {
  &__wrapper {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    margin: 20px 0;
    padding: 5px 10px 0px 10px;
    border-radius: 10px;
    font-size: 6vw;
    font-weight: 900;
    // text-stroke:5px #000000;
    line-height: 4vw;
    background-color: rgba(195, 195, 195, 0.4);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: 1001;
    transition: all 0.4s ease-in-out;
    &.active {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    &.top {
      top: 0;
    }
    &.bottom {
      bottom: 0;
    }
  }

  &__inner {
    border-radius: 10px;
    padding: calc(10px + 0.2vw) 20px 10px 20px;
    // border-left: 1px solid var(--purple);
    // border-right: 1px solid var(--purple);
  }
}

@include mobile {
  .title {
    &__wrapper {
        font-size: 10vw;
        line-height: 8.5vw;
        text-align: center;
      &.active {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}

@import "../../styles/animations";

.preloader{

    &__wrapper{
        width: 100%;
        height: var(--app-height);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        background-color: #4e5278;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.4s ease-in-out;
        &.active{
            opacity: 1;
            pointer-events: all;
        }
    }

    &__gradient-1,
    &__gradient-2,
    &__gradient-3{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__gradient-1{
        background-image: linear-gradient(#29332c, #878787);
        animation: opacity-change1 linear 5s infinite;
    }
    &__gradient-2{
        background-image: linear-gradient(#52705d, #02240f);
        animation: opacity-change2 linear 5s infinite;
    }
    &__gradient-3{
        background-image: linear-gradient(#353b37 20%, #031409 90%);
        animation: opacity-change3 linear 5s infinite;
    }

    &__loading-wrapper{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 10px 10px 20px 10px;
        background-color: rgba(195, 195, 195, 0.4);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        border-radius: 10px;
    }

    &__loadbar-outer{
        width: 200px;
        height: 20px;
        margin: 10px auto;
        padding: 3px;
        border-left: 1px solid var(--purple);
        border-right: 1px solid var(--purple);
        border-radius: 10px;
    }

    &__loadbar-inner{
        height: calc(100% - 1px);
        background-color: var(--purple);
        border-radius: 10px;
        transition: 
        opacity 0.2s ease-in-out, 
        border 0.2s ease-in-out,
        background-color 0.2s ease-in-out;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        pointer-events: none;
        &.done{
            border: 1px solid var(--purple);
            background-color: transparent;
            cursor: pointer;
            pointer-events: all;
            &:hover{
                background-color: var(--purple);
                color: #FFF;
            }
        }
    }
    &__enter-button{
        opacity: 0;
        pointer-events: none;
        transition: all 0.4s ease-in-out;
        &.active{
            opacity: 1;
        }
    }

    &__loading-text{
        text-align: center;
        animation: pulse 1s linear infinite;
        font-style: italic;
        &.disabled{
            animation: none;
            opacity: 0;
        }
    }
}